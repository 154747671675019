<template>
  <div>
    <p class="mb-0">
      Which of the following imaginary salts has the
      <span class="text-bold text-danger">highest</span>
      molar solubility,
      <stemble-latex content="$S?$" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question170i',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {
          expression: '$\\ce{AB2(s), K}_{\\text{sp}}=3.86\\times 10^{-17}$',
          value: 'AB2low',
        },
        {
          expression: '$\\ce{AB(s),\\,\\, K}_{\\text{sp}}=1.84\\times 10^{-16}$',
          value: 'ABlow',
        },
        {
          expression: '$\\ce{AB2(s), K}_{\\text{sp}}=9.12\\times 10^{-16}$',
          value: 'AB2high',
        },
        {
          expression: '$\\ce{A3B(s), K}_{\\text{sp}}=7.43\\times 10^{-16}$',
          value: 'A3B',
        },
        {
          expression: '$\\ce{AB(s),\\,\\, K}_{\\text{sp}}=8.70\\times 10^{-15}$',
          value: 'ABhigh',
        },
      ],
    };
  },
};
</script>
